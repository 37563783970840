import request from "./request";

/**
 * 外链
 */

/**
 * 查询
 */
export const list = data => request.get("/link/q", { params: data });

/**
 * 新建
 */
export const create = data => request.post("/link/", data);

/**
 * 是否开通服务
 */
export const check = () => request.get("/link/check");

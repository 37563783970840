<template>
  <Layout>
    <div class="app-link-list">
      <a-page-header title="外链" />

      <div class="tool">
        <a-form layout="inline">
          <a-button v-if="hasPermission" @click="add" type="primary">
            <a-icon type="file-add" /> 新建外链
          </a-button>
          <a-button v-else @click="auth" type="primary">
            <a-icon type="scan" /> 扫码授权
          </a-button>
        </a-form>
      </div>
      <div class="table-wrap">
        <a-table
          :columns="columns"
          :data-source="list"
          :pagination="pagination"
          :loading="loading"
          @change="handleTableChange"
        >
          <span slot="action" slot-scope="_, record">
            <a-button
              type="link"
              size="small"
              icon="copy"
              @click="copyText(record.link)"
            >
              复制
            </a-button>
          </span>
        </a-table>
      </div>
    </div>

    <!-- 添加 -->
    <a-modal :width="600" v-model="dialogVisible" title="生成外链" @ok="submit">
      <a-form-model class="form-link" :model="form" ref="form">
        <a-row :gutter="16">
          <a-col :span="24">
            <a-form-model-item
              required
              label="外链名称"
              prop="name"
              :rules="[
                {
                  required: true,
                  message: '外链名称必填'
                }
              ]"
            >
              <a-input v-model="form.name" />
            </a-form-model-item>
          </a-col>
        </a-row>
        <a-row :gutter="16">
          <a-col :span="6">
            <a-form-model-item label="跳转到">
              <a-select
                v-model="res.target"
                @change="onChangeTarget"
                placeholder="请选择"
              >
                <a-select-option
                  v-for="t in targetList2"
                  :key="t.id"
                  :value="t.id"
                  >{{ t.name }}
                </a-select-option>
              </a-select>
            </a-form-model-item>
          </a-col>
          <a-col :span="18">
            <a-form-model-item label="地址">
              <!-- 商品 -->
              <a-select
                show-search
                :filter-option="filterOption"
                v-if="res.target == 1"
                @change="onChangeValue"
                placeholder="请选择"
              >
                <a-select-option
                  v-for="p in productList"
                  :key="p.id"
                  :value="p.id"
                  >{{ p.title }}
                </a-select-option>
              </a-select>

              <!-- 带货商品 -->
              <a-select
                show-search
                :filter-option="filterOption"
                v-if="res.target == 6"
                @change="onChangeValue"
                placeholder="请选择"
              >
                <a-select-option
                  v-for="p in productList2"
                  :key="p.id"
                  :value="p.id"
                  >{{ p.title }}
                </a-select-option>
              </a-select>

              <!-- 优惠券 -->
              <a-select
                v-if="res.target == 2"
                @change="onChangeValue"
                placeholder="请选择"
                notFoundContent="请在小商店后台创建优惠券"
              >
                <a-select-option
                  v-for="c in couponList"
                  :key="c.id"
                  :value="c.id"
                  >{{ c.name }}
                </a-select-option>
              </a-select>

              <!-- 分类 -->
              <a-tree-select
                v-if="res.target == 3"
                @select="onChangeValue"
                style="width: 100%"
                :dropdown-style="{ maxHeight: '400px', overflow: 'auto' }"
                :tree-data="categoryTree"
                placeholder="请选择"
                notFoundContent="请在小商店后台创建店铺分类"
                tree-default-expand-all
              >
              </a-tree-select>

              <!-- 页面 -->
              <a-select
                v-if="res.target == 4"
                @change="onChangeValue"
                placeholder="请选择"
              >
                <a-select-option v-for="c in pageList" :key="c.id" :value="c.id"
                  >{{ c.name }}
                </a-select-option>
              </a-select>

              <!-- 拼团 -->
              <a-select
                show-search
                :filter-option="filterOption"
                v-if="res.target == 7"
                @change="onChangeValue"
                placeholder="请选择"
              >
                <a-select-option v-for="c in pinList" :key="c.id" :value="c.id"
                  >{{ c.title }}
                </a-select-option>
              </a-select>

              <!-- 砍价 -->
              <a-select
                show-search
                :filter-option="filterOption"
                v-if="res.target == 8"
                @change="onChangeValue"
                placeholder="请选择"
              >
                <a-select-option v-for="c in kanList" :key="c.id" :value="c.id"
                  >{{ c.title }}
                </a-select-option>
              </a-select>

              <!-- 分享有礼 -->
              <a-select
                show-search
                :filter-option="filterOption"
                v-if="res.target == 9"
                @change="onChangeValue"
                placeholder="请选择"
              >
                <a-select-option
                  v-for="c in shareList"
                  :key="c.id"
                  :value="c.id"
                  >{{ c.title }}
                </a-select-option>
              </a-select>

              <!-- 抽奖 -->
              <a-select
                show-search
                :filter-option="filterOption"
                v-if="res.target == 14"
                @change="onChangeValue"
                placeholder="请选择"
              >
                <a-select-option
                  v-for="c in prizeList"
                  :key="c.id"
                  :value="c.id"
                  >{{ c.title }}
                </a-select-option>
              </a-select>

              <!-- 招募推荐官 -->
              <a-input
                v-if="res.target == 11"
                @change="onChangeText"
                :max-length="10"
                type="number"
                placeholder="请复制招募计划 planId"
              />
            </a-form-model-item>
          </a-col>
        </a-row>
        <a-row v-if="res.target === 0" :gutter="16">
          <a-col :span="12">
            <a-form-model-item label="页面路径" prop="path">
              <a-input
                v-model="form.path"
                placeholder="示例：/pages/index/index，不填默认跳转首页"
              />
            </a-form-model-item>
          </a-col>
          <a-col :span="12">
            <a-form-model-item label="参数" prop="query">
              <a-input
                v-model="form.query"
                placeholder="参数示例：x=123&y=456"
              />
            </a-form-model-item>
          </a-col>
        </a-row>

        <!-- 过期设置 -->
        <a-form-model-item
          label="链接时效"
          prop="is_expire"
          label-align="left"
          class="fluid-row"
        >
          <a-radio-group v-model="form.is_expire">
            <a-radio :value="false">永久链接</a-radio>
            <a-radio :value="true">临时链接</a-radio>
          </a-radio-group>
        </a-form-model-item>

        <template v-if="form.is_expire">
          <a-row :gutter="16">
            <a-col :span="12">
              <a-form-model-item
                label="失效类型"
                prop="expire_type"
                class="fluid-row"
              >
                <a-radio-group v-model="form.expire_type" button-style="solid">
                  <a-radio-button :value="1">N天有效</a-radio-button>
                  <a-radio-button :value="0">失效时间</a-radio-button>
                </a-radio-group>
              </a-form-model-item>
            </a-col>

            <a-col :span="12">
              <a-form-model-item
                v-if="form.expire_type === 1"
                label="天数"
                prop="expire_interval"
                class="fluid-row"
                :rules="[
                  {
                    required: true,
                    message: '天数必填'
                  }
                ]"
              >
                <a-input-number
                  :min="1"
                  :max="365"
                  v-model="form.expire_interval"
                />
              </a-form-model-item>

              <a-form-model-item
                v-else
                label="失效时间"
                prop="expire_time"
                class="fluid-row"
                required
                :rules="[
                  {
                    required: true,
                    message: '失效时间必填'
                  }
                ]"
              >
                <a-date-picker
                  v-model="form.expire_time"
                  :disabled-date="disabledExpireDate"
                />
              </a-form-model-item>
            </a-col>
          </a-row>
        </template>
      </a-form-model>
    </a-modal>
  </Layout>
</template>

<script>
import Layout from "@/layout/index.vue";

import { mapState } from "vuex";

import loadDataMixin from "@/mixins/loadData";

import targetMixin from "@/mixins/target";
import productMixin from "@/mixins/product";
import couponMixin from "@/mixins/coupon";
import categoryMixin from "@/mixins/category";
import pageMixin from "@/mixins/page";

import wxacodeMixin from "@/mixins/wxacode";

const UrlLinkSvc = require("@/service/url_link");

const BASE_URL = "plugin-private://wx34345ae5855f892d/pages";
const MINSHOP_PATH = [
  { id: 100, name: "首页", path: "/pages/index/index", query: "" },
  // {
  //   id: 101,
  //   name: "分类Tab",
  //   path: BASE_URL + "/category/index",
  //   query: ""
  // },

  {
    id: 1,
    name: "自营商品",
    path: BASE_URL + "/productDetail/productDetail",
    query: "productId="
  },
  {
    id: 6,
    name: "带货商品",
    path: BASE_URL + "/cpsProductDetail/cpsProductDetail",
    query: "productId="
  },
  {
    id: 2,
    name: "优惠券",
    path: "plugin-private://wxd14f33852509c129/pages/couponDetail/index",
    query: "couponId="
  },
  {
    id: 3,
    name: "分类商品",
    path: "plugin-private://wxd14f33852509c129/pages/categoryProduct/index",
    query: "categoryId="
  },

  {
    id: 7,
    name: "拼团",
    path: "plugin-private://wxd14f33852509c129/pages/pinDetail/index",
    query: "id="
  },
  {
    id: 8,
    name: "砍价",
    path: "plugin-private://wxd14f33852509c129/pages/kanDetail/index",
    query: "id="
  },

  {
    id: 4,
    name: "页面",
    path: "plugin-private://wxd14f33852509c129/pages/page/index",
    query: "pageId="
  },
  { id: 5, name: "直播", path: BASE_URL + "/liveRooms/liveRooms", query: "" },

  {
    id: 9,
    name: "分享有礼",
    path: "plugin-private://wxd14f33852509c129/pages/shareDetail/index",
    query: "id="
  },
  {
    id: 10,
    name: "会员中心",
    path: "plugin-private://wxd14f33852509c129/pages/vip/index",
    query: ""
  },

  {
    id: 11,
    name: "招推荐官",
    path: BASE_URL + "/recommend/recommend",
    query: "planId="
  },
  {
    id: 13,
    name: "群裂变",
    path: "plugin-private://wxd14f33852509c129/pages/qun/index",
    query: ""
  },
  {
    id: 14,
    name: "抽奖",
    path: "plugin-private://wxd14f33852509c129/pages/prize/index",
    query: "id="
  }
];

export default {
  name: "LinkList",

  data() {
    return {
      list: [],
      filters: {},
      pagination: {
        showTotal: total => `共 ${total} 条记录`
      },
      loading: false,

      dialogVisible: false,
      MINSHOP_PATH,

      res: {
        target: 0
      },

      form: {
        name: "",
        path: "",
        query: "",

        is_expire: false,
        expire_type: 1,
        expire_time: null,
        expire_interval: 365
      }
    };
  },

  mixins: [
    wxacodeMixin,
    loadDataMixin,
    targetMixin,
    productMixin,
    couponMixin,
    categoryMixin,
    pageMixin
  ],

  computed: {
    ...mapState({
      func: state => state.func
    }),
    hasPermission() {
      return this.func.includes(88); // URL Schema
    },

    targetList2() {
      const defaultList = [
        { id: 100, name: "首页" }
        // {
        //   id: 101,
        //   name: "分类Tab"
        // }
      ];
      let targetList = this.targetList.map(x => {
        if (x.id === 0) {
          return { ...x, name: "自定义" };
        } else {
          return x;
        }
      });

      // 去除拨打电话、复制、弹窗
      targetList = targetList.filter(x => ![20,21,22].includes(x.id))

      return [...defaultList, ...targetList];
    },

    maxExpireDateTime() {
      return window.moment().add(365, "days");
    },

    columns() {
      // const { filters } = this;

      return [
        {
          title: "名称",
          dataIndex: "name",
          key: "name",
          width: 100
        },
        {
          title: "页面路径",
          dataIndex: "path",
          key: "path"
        },
        {
          title: "参数",
          dataIndex: "query",
          key: "query"
        },
        {
          title: "有效期",
          dataIndex: "is_expire",
          key: "is_expire",
          width: 140,
          customRender: (_, record) => {
            if (!record.is_expire) {
              return "永久有效";
            }

            switch (record.expire_type) {
              case 0:
                return window.moment(record.expire_time).format("YYYY-MM-DD");
              case 1:
                return record.expire_interval + "天";
            }
          }
        },
        {
          title: "外链",
          dataIndex: "link",
          key: "link",
          width: 240
        },
        {
          title: "操作",
          key: "action",
          width: 100,
          scopedSlots: { customRender: "action" }
        }
      ];
    }
  },

  methods: {
    async auth() {
      this.$root.$emit("auth");
    },

    onChangeTarget() {
      const { path, query } = MINSHOP_PATH.filter(
        x => x.id === this.res.target
      )[0];
      this.form.path = path;
      this.form.query = query;
    },
    onChangeValue(val) {
      const { query } = MINSHOP_PATH.filter(x => x.id === this.res.target)[0];
      this.form.query = query + val;
    },
    onChangeText(e) {
      const { query } = MINSHOP_PATH.filter(x => x.id === this.res.target)[0];
      this.form.query = query + e.target.value;
    },

    disabledExpireDate(date) {
      return date.isAfter(this.maxExpireDateTime);
    },

    add() {
      this.res.target = 0;
      this.form.name = "";
      this.form.path = "";
      this.form.query = "";

      this.dialogVisible = true;
    },

    async handleSearch() {
      await this.fetch(this.filters);
    },

    async handleTableChange(pagination, filters) {
      const pager = { ...this.pagination };
      pager.current = pagination.current;
      this.pagination = pager;
      this.filters = filters;

      const { pageSize, current: page } = pagination;
      await this.fetch({
        pageSize,
        page,
        ...filters
      });
    },

    async fetch(params = {}) {
      this.loading = true;

      try {
        const data = await UrlLinkSvc.list({
          page: 1,
          pageSize: 10,
          ...params
        });

        const pagination = {
          ...this.pagination,
          total: data.cnt,
          current: data.page
        };
        this.list = data.res;
        this.pagination = pagination;

        this.loading = false;
      } catch (err) {
        this.loading = false;

        console.error(err);
        this.$message.error("加载数据出错，错误：" + err.message);
      }
    },

    async submit() {
      this.$refs.form.validate(async valid => {
        if (valid) {
          let {
            name,
            path,
            query,

            is_expire,
            expire_type,
            expire_time,
            expire_interval
          } = this.form;

          try {
            let val = {
              name,
              path,
              query,
              is_expire,
              expire_type,
              expire_time,
              expire_interval
            };

            await UrlLinkSvc.create(val);
            this.$message.info("保存成功");

            this.$refs.form.resetFields();

            this.dialogVisible = false;

            await this.fetch({ ...this.filters, page: 1 });
          } catch (err) {
            console.error(err);
            this.$message.error("保存失败，错误：" + err.message);
          }
        } else {
          console.log("error submit!!");

          return false;
        }
      });
    }
  },

  async mounted() {
    const { ok } = await UrlLinkSvc.check();
    if (!ok) {
      this.$message.info("请先开通服务");
      this.$router.replace({ name: "Vip" });
      return;
    }

    await this.fetch();
  },

  components: {
    Layout
  }
};
</script>

<style lang="scss">
.app-link-list {
  display: flex;
  flex-direction: column;
  min-height: calc(100vh - var(--header-height));
  background: #f0f2f5;
  padding: 24px 24px 0;

  .tool {
    padding: 24px 24px;
    background: #fff;
    display: flex;
    justify-content: space-between;
  }
  .table-wrap {
    background: #fff;

    .ant-table-pagination.ant-pagination {
      float: none;
      text-align: center;
    }
  }
}

.fluid-row {
  .ant-col {
    float: right;

    &.ant-form-item-label {
      float: left;
    }
  }
}
</style>
